import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { elastic as Menu } from 'react-burger-menu'
import Modal from 'react-modal';
import AppointmentModal from "./Global/AppointmentModal"

import { pageView } from "../utils/fbApi"

import Cookies from 'js-cookie';

import logoWhite from "../images/next-you-logo-head.png";
// import sortDown from "../images/sort-down-solid.svg"
import sortDown from "../images/chevron-down-solid.svg";
import menuBg from "../images/menu-tab-bg.jpg";

import "./Header.css";

const burgerStyles = {
    bmBurgerButton: {
        display: "block",
        position: "absolute",
        width: "26px",
        height: "22px",
        left: "25px",
        top: "30px"
    },
    bmBurgerBars: {
        background: 'rgb(96 154 51)'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0!important",
        left: "0",
        backgroundImage: `url(${menuBg})`
    },
    bmMenu: {
        // background: "rgb(96 154 51)",
        padding: "0.5em 1.5em 0",
        fontSize: "1.15em",
        minWidth: "300px",
        backgroundImage: `url(${menuBg})`,
        backgroundSize: "cover",
    },
    bmMorphShape: {
        fill: 'rgb(96 154 51)'
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em"
    },
    bmItem: {
        display: "block!important",
        marginTop: "1rem",
        outline: "none",
        color: "#555",
        fontSize: "16px"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0!important"
    }
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
    },
    overlay: {
        backgroundColor: "#212121bf"
    }
};

// const getCookie = (name) => {
//     return document.cookie.split('; ').reduce((r, v) => {
//       const parts = v.split('=')
//       return parts[0] === name ? decodeURIComponent(parts[1]) : r
//     }, '')
//   }

const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function Header(props) {

    const [menuState, setMenuState] = useState(false);
    const [appointmentModal, setAppointmentModal] = useState(false);
    const menu = props.headerMenu ? props.headerMenu.menuItems.edges : [];

    let isMenuOpen = function (state) {
        setMenuState(state.isOpen)
    };

    useEffect(() => {
        const queryString = window.location.search;
        const parameters = new URLSearchParams(queryString);
        // const fbclid = parameters.get('fbclid');

        // if (fbclid) {
        //     localStorage.setItem('fbclid', fbclid)
        // }

        // let fbId = localStorage.getItem("fbclid")

        // if the item doesn't exist, return null
        if (window.evId) {
            pageView(window.evId)
        }

    }, [])

    useEffect(() => {
        if (!menuState) {
            if (document.getElementsByClassName('bm-menu-wrap')) {
                document.getElementsByClassName('bm-menu-wrap')[0].style.visibility = 'hidden';
            }
        } else {
            if (document.getElementsByClassName('bm-menu-wrap')) {
                document.getElementsByClassName('bm-menu-wrap')[0].style.visibility = 'visible';
            }
        }
    }, [menuState])


    let nestCounter = 0;

    function createSubMenu(subs, parentLabel, parentUrl) {
        if (parentUrl.includes("ypiresies")) {
            return (
                <ul>
                    {subs.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            <Link to={`/ypiresies${subLink.node.url.replace("https://admin.nextyounutrition.gr/service", "")}`} className="menu-g-link">
                                {subLink.node.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            )
        } else {
            return (
                <ul>
                    {subs.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            <Link to={`/${subLink.node.url.replace("http://", "")}`} className="menu-g-link">
                                {subLink.node.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            )
        }

    }

    function createSubMobile(subs, parentLabel) {
        console.log(subs, parentLabel)
        if (parentLabel === "Υπηρεσίες" || parentLabel === "Services") {
            return (
                <ul className="pl-4 pt-2">
                    {subs.map((subLink, index) => (
                        <li key={`sub-mob-link-${index}`} className="flex" >
                            <Link to={`/ypiresies${subLink.node.url.replace("https://admin.nextyounutrition.gr/service", "")}/`} className="py-2" >
                                {subLink.node.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            )
        }
    }

    function subMenuClick(index) {
        let isOpen = document.querySelectorAll(".m-dropdown-content")[index].style.display;

        if (isOpen === "none" || isOpen === "") {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "block";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(90deg)";
        } else {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "none";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(0deg)";
        }
    }


    return (
        <div className="nav-container container mx-auto px-5 flex md:block justify-center" id="headerContainer">
            <nav className="flex flex-row w-full md:justify-between">
                <div className="flex flex-row justify-center  md:justify-between w-full md:w-auto relative">
                    <div id="mainMenu" className="block md:hidden"></div>
                    <Menu styles={burgerStyles} isOpen={menuState} onStateChange={isMenuOpen} className="block md:hidden" >
                        <div className="pb-4">
                            <Link to={`/`}>
                                <img className="w-32 m-auto mn-logo" src={logoWhite} alt="white-logo" />
                            </Link>
                        </div>

                        {menu.map((tab, index) => {
                            return (
                                <div key={`mMenu-${index}`} className="menu-item text-left text-white" style={{ "marginTop": "1rem" }}>

                                    {tab.node.childItems.edges.length > 0
                                        ?
                                        <React.Fragment>
                                            <div onClick={() => subMenuClick(index)} className="flex justify-between items-center" >
                                                <span className="h-dropdown">
                                                    {tab.node.label}
                                                </span>
                                                <img className="mob-sort-down h-chev-down" src={sortDown} alt="sort-down" />
                                            </div>
                                            <div className="m-dropdown-content hidden">
                                                {tab.node.childItems.edges.length > 0 && (
                                                    createSubMobile(tab.node.childItems.edges, tab.node.label)
                                                )}
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {(tab.node.url.includes("home"))
                                                ?
                                                <Link to={`${tab.node.url.replace("http://home", "")}/`}>
                                                    {tab.node.label}
                                                </Link>
                                                :
                                                <Link to={`/${tab.node.url.replace("http://", "")}/`}>
                                                    {tab.node.label}
                                                </Link>
                                            }
                                            <div className="h-chev-down hidden"></div>
                                            <div className="m-dropdown-content hidden"></div>
                                        </React.Fragment>
                                    }
                                </div>
                            )
                        })}

                    </Menu>
                    <Link to={`/`} className="flex items-center">
                        <img className="h-logo m-auto" src={logoWhite} alt="white-logo" />
                    </Link>
                </div>
                <div className="hidden md:flex items-center text-xl">
                    <ul className="hidden md:flex md:flex-row nav">
                        {menu.map((tab, index) => {
                            return (
                                <li key={`hMenu-${index}`} className="pr-8">
                                    {tab.node.url === "#"
                                        ?
                                        <span>
                                            {tab.node.label}
                                        </span>
                                        :
                                        (tab.node.url.includes("home")
                                            ?
                                            <Link to={`${tab.node.url.replace("http://home", "")}/`}>
                                                {tab.node.label}
                                            </Link>
                                            :
                                            <Link to={`/${tab.node.url.replace("http://", "")}/`}>
                                                {tab.node.label}
                                            </Link>
                                        )

                                    }
                                    {(tab.node.childItems.edges && tab.node.childItems.edges.length > 0) && (
                                        createSubMenu(tab.node.childItems.edges, tab.node.label, tab.node.url)
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                    <button onClick={() => setAppointmentModal(true)} className="h-appointment-btn">
                        Κλείστε ραντεβού
                    </button>
                </div>
            </nav>
            <Modal
                isOpen={appointmentModal}
                onRequestClose={() => setAppointmentModal(false)}
                style={customStyles}
                contentLabel="Appointment Modal"
            >
                <AppointmentModal
                    cpt={props.appointment}
                    setAppointmentModal={setAppointmentModal}
                />
            </Modal>
        </div>
    )
}

export default Header;
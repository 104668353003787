export const pageView = function (fbclid) {
    if (fbclid) {
        fetch('https://admin.nextyounutrition.gr/wp-json/myplugin/v1/getInfo')
            .then(response => response.json())
            .then((data) => {
                fetch('https://graph.facebook.com/v10.0/163187788976281/events/?access_token=EAAB56ZCrMsZAEBABkyFdRFeZBVHEcdkwA2mDQWpoECaZAHjhKby2RL4ZAMXJym3lE1ruxY3w4fuZCZAP0z2cnTw8GNQJlaZAOHpRu6tfvTwLYc3xMzntZBdBtM1ceGg9ZC1dnVJuIp6NplTij1rzEPKWKZBBFETslRlOD23sJg4I7aPcj5WLwOjCHwpCEY7QjFhyxUZD', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        'data': [
                            {
                                "event_name": "PageView",
                                "event_id": fbclid,
                                "event_source_url": window.location.href.split('?')[0],
                                "event_time": Math.floor(new Date().getTime() / 1000),
                                "action_source": "website",
                                "user_data": {
                                    "client_ip_address": data.ip,
                                    "client_user_agent": data.browser
                                },
                            }
                        ]
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data)
                    })
                    .catch((err) => console.log(err));
            });
    }

}
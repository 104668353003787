
import React from "react"
import HeaderMenu from "./Header"
import FooterMenu from "./Footer"
import { Helmet } from "react-helmet"
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";



const Layout = ({ children, header, footer, appointment, metaData }) => (
    <div>
        {metaData && (
            <Helmet>
                <html lang={"el"} />
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
                <link rel="canonical" href={metaData.canonical} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />

                {(metaData.noIndex && metaData.noIndex === "noindex") && (
                    <meta name="robots" content="noindex" />
                )}

                <meta property="og:title" content={metaData.fbTitle} />
                <meta property="og:description" content={metaData.fbDescription} />
                <meta property="og:url" content={metaData.canonical} />
                <meta property="og:type" content={metaData.fbType} />
                <meta property="og:locale" content={metaData.locale} />
                <meta property="og:image" content={metaData.image} />
                <meta property="og:image:width" content="598" />
                <meta property="og:image:height" content="399" />

            </Helmet>
        )}

        <HeaderMenu headerMenu={header} appointment={appointment} />
        {children}
        <ScrollUpButton
            style={{ backgroundColor: "#7cab59", borderColor: "#7cab59", fill: "#ffffff", outline: "none", zIndex: 9 }}
        />
        <FooterMenu footerMenu={footer} appointment={appointment} />

    </div>

)

export default Layout


import React, { useState, useEffect } from 'react'
import axios from "axios"
import { format, parseISO } from 'date-fns'
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import isSameDay from 'date-fns/isSameDay'
import Img from "gatsby-image"
import DatePicker, { registerLocale } from "react-datepicker"
import { el } from 'date-fns/locale'
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval'


import { sha256 } from 'js-sha256';


import Times from "./../../images/times-solid-black.svg"
// import subDays from 'date-fns/subDays'
import addMonths from 'date-fns/addMonths'
import "react-datepicker/dist/react-datepicker.css";
import "./AppointmentModal.css";

registerLocale("el", el);

function AppointmentModal(props) {
    const data = props.cpt

    const [firstRender, setFirstRender] = useState(true);
    const [firstRenderEr, setFirstRenderEr] = useState(true);


    const [startDate, setStartDate] = useState(
        setHours(setMinutes(new Date(), 0), 9)
    );

    const [booked, setBooked] = useState();
    const [exTimes, setExTimes] = useState();
    const [bookComplete, setBookComplete] = useState(false);
    const [bookDateFinal, setBookDateFinal] = useState();
    const [error, setError] = useState("");
    const [exDates, setExDates] = useState([]);




    useEffect(() => {
        let apps = [];

        axios.get(`https://admin.nextyounutrition.gr/wp-json/wp/v2/appointment?per_page=100`)
            .then(function (response) {
                if (response.data && response.data.length > 0) {
                    apps = apps.concat(response.data)
                }
                axios.get(`https://admin.nextyounutrition.gr/wp-json/wp/v2/appointment/?per_page=100&offset=99`)
                    .then(function (response) {
                        if (response.data && response.data.length > 0) {
                            apps = apps.concat(response.data)
                        }

                        axios.get(`https://admin.nextyounutrition.gr/wp-json/wp/v2/appointment/?per_page=100&offset=199`)
                            .then(function (response) {
                                if (response.data && response.data.length > 0) {
                                    apps = apps.concat(response.data)
                                }
                                setBooked(apps)

                                changeDt(startDate, apps)

                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })

            })
            .catch(function (error) {
                // handle error
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (booked && firstRender) {
            let weekends = eachWeekOfInterval({
                start: new Date(2019, 9, 13),
                end: new Date(2025, 9, 13)
            })


            booked.map((app, i) => {
                // console.log(app)
                if (app.acf.name === "ClosedDate") {
                    weekends.push(new Date(app.acf.datetime))
                }
            })

            // console.log(weekends)
            setExDates(weekends)

            setFirstRender(false)
        }
    }, [booked, firstRender])

    function changeDt(date, bookedList) {
        let excBooked = [];

        // console.log(bookedList)


        if (bookedList) {
            bookedList.map((app, i) => {
                if (isSameDay(new Date(app.acf.datetime), new Date(date))) {
                    excBooked.push(new Date(app.acf.datetime))
                }
            })
        }

        // console.log(excBooked)


        setExTimes(
            excBooked
        )

        setStartDate(date)

        setTimeout(() => {
            if (firstRenderEr) {
                let elements = document.getElementsByClassName("react-datepicker__time-list-item--disabled");
                let i;
                for (i = 0; i < 18; i++) {
                    if (elements[0]) {
                        elements[0].parentNode.removeChild(elements[0]);
                    }
                }
                setFirstRenderEr(false)
            }
        }, 1000)
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    console.log(props)

    function book() {
        let formData = new FormData();

        console.log(exTimes)
        console.log(startDate)
        console.log(exTimes.includes(startDate))
        let modelName = document.getElementById("modal-name").value;
        let modelEmail = document.getElementById("modal-email").value;
        let modelService = document.getElementById("modal-service").value;

        if (!modelName || !(modelName.length > 3)) {
            setError("Παρακαλούμε συμπληρώστε το πεδίο του ονόματος")
        }

        console.log(modelEmail)
        if (modelEmail === "") {
            setError("Παρακαλούμε συμπληρώστε το πεδίο του ηλεκτρονικού ταχυδρομείου")
        } else if (!(validateEmail(modelEmail))) {
            setError("Η διεύθυνση ηλεκτρονικού ταχυδρομείου δεν είναι έγκυρη")
        }



        if ((modelName && (modelName.length > 3)) && (validateEmail(modelEmail))) {
            let model = {
                displayDate: format(startDate, 'dd/MM/yyyy H:mm'),
                startDate: startDate,
                name: modelName,
                email: modelEmail,
                service: modelService
            }

            formData.append('model', JSON.stringify(model));
            axios.post(`https://admin.nextyounutrition.gr/wp-json/myplugin/v1/bookAppointment`, formData)
                .then(function (response) {
                    console.log(response)
                    setBookComplete(true)
                    setBookDateFinal(model.displayDate)

                    window.dataLayer.push({
                        'event': 'book_appointment'
                    });

                    window.fbq('track', 'Schedule');

                    const fbId = localStorage.getItem("fbclid")

                    if (fbId) {
                        fetch('https://graph.facebook.com/v10.0/163187788976281/events/?access_token=EAAB56ZCrMsZAEBABkyFdRFeZBVHEcdkwA2mDQWpoECaZAHjhKby2RL4ZAMXJym3lE1ruxY3w4fuZCZAP0z2cnTw8GNQJlaZAOHpRu6tfvTwLYc3xMzntZBdBtM1ceGg9ZC1dnVJuIp6NplTij1rzEPKWKZBBFETslRlOD23sJg4I7aPcj5WLwOjCHwpCEY7QjFhyxUZD', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                'data': [
                                    {
                                        "event_name": "Schedule",
                                        "event_id": fbId,
                                        "event_source_url": window.location.href.split('?')[0],
                                        "event_time": Math.floor(new Date().getTime() / 1000),
                                        "action_source": "website",
                                        "user_data": {
                                            "em": sha256(modelEmail),
                                            "client_user_agent": navigator.userAgent
                                        },
                                    }
                                ]
                            }),
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                console.log(data)
                            })
                            .catch((err) => console.log(err));
                    }
                })

                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
        } else {
            setError("Παρακαλούμε συμπληρώστε όλα τα πεδία")
        }
    }

    return (
        <div className="w-full relative">
            <div className="absolute z-10" style={{ right: "10px", top: "10px" }}>
                <img src={Times} style={{ width: "20px" }} onClick={() => props.setAppointmentModal(false)} />
            </div>
            <div className="flex flex-wrap m-auto py-0">
                <div className="w-full relative app-m-w m-auto" >
                    <div>
                        <Img fluid={data.appBg.imageFile.childImageSharp.fluid} className="app-tab-bg" />
                    </div>

                    {!bookComplete && (
                        <div className="p-8 app-form">
                            <h4 className="app-h4">{data.appTitle}</h4>
                            <h5>{data.appSubtitle}</h5>
                            <div>
                                <input type="text" name="name" id="modal-name" placeholder={data.form.name} />
                            </div>
                            <div>
                                <input type="email" name="email" id="modal-email" placeholder={data.form.email} />
                            </div>
                            <div>
                                <select className="app-select" name="service" id="modal-service">
                                    {data.form.typeOfService.options.map((option, i) => (
                                        <option key={`service-option-${i}`} value={option.label} >{option.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <div style={{ color: "#555" }}>
                                    {data.form.preferredDate.label}
                                </div>
                                <DatePicker
                                    locale="el"
                                    selected={startDate}
                                    onChange={date => changeDt(date, booked)}
                                    // excludeDates={[new Date(), subDays(new Date(), 1)]}
                                    popperPlacement="top-end"
                                    minDate={new Date()}
                                    maxDate={addMonths(new Date(), 5)}
                                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                                    maxTime={setHours(setMinutes(new Date(), 0), 20)}
                                    showDisabledMonthNavigation
                                    showTimeSelect
                                    excludeDates={exDates}
                                    excludeTimes={exTimes}
                                    timeIntervals={60}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </div>
                            { error && (
                                <div style={{ maxWidth: "420px", color: "red" }}>
                                    <p>{error}</p>
                                </div>
                            )}
                            <button onClick={book} className="app-bttn my-8">{data.form.submitButton}</button>
                        </div>
                    )}

                    {bookComplete && (
                        <div className="app-response-mssg">
                            <h4>Ευχαριστούμε που μας επιλέξατε!</h4>
                            <p>Το ραντεβού σας έκλεισε για της {bookDateFinal ? bookDateFinal : ""} </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AppointmentModal;



export const query = graphql`
    fragment AppointmentModalFragment on WPGraphQL_Template_Appcpt {
            appSectionBg{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fluid(maxWidth: 1920, quality:80){
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                        }
                    }
                }
            }
            appBg{
                sourceUrl
                altText
                imageFile{
                    childImageSharp{
                        fluid(maxWidth: 600, quality:80){
                            base64
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                        }
                    }
                }
            }
            appTitle
            appSubtitle
            form {
                name
                email
                typeOfService {
                    label
                    options{
                        label
                    }
                }
                preferredDate {
                    label
                    date
                }
                submitButton
            }
        
    }
`
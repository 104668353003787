import React, { useState } from 'react'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import Modal from 'react-modal';
import AppointmentModal from "./Global/AppointmentModal"

import fbWhite from "../images/facebook-icon.svg";
import instagram from "../images/instagram-icon.svg";
import Telephone from "../images/phone-solid.svg";
import Email from "../images/envelope-solid.svg";
import Clock from "../images/clock-regular.svg";
import Calendar from "../images/calendar-alt-solid.svg";
import "./Footer.css";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
    },
    overlay: {
        backgroundColor: "#212121bf"
    },
    portal: {
        zIndex: "99999",
        position: "relative"
    }
};

function Footer(props) {

    const [appointmentModal, setAppointmentModal] = useState(false);

    const data = props.footerMenu;
    console.log(props)

    return (
        <footer className="footer mt-32">
            <div className="p-4 py-8 md:p-16 flex flex-wrap container mx-auto justify-center footer-wrapper" style={{ width: "90%", maxWidth: "1200px" }}>
                <div className=" w-full lg:w-2/3">
                    <div className="f-map-wrapper px-8">
                        <a href={data.footerInformationTemplate.address.addressGoogleLink} aria-label={"google-map"} rel="noreferrer" className="cursor-pointer" target="_blank">
                            <Img fluid={data.footerAcf.googlaMapsImage.imageFile.childImageSharp.fluid} className="f-map" />
                        </a>
                    </div>
                </div>
                <div className=" w-full lg:w-1/3 pt-8 lg:pt-0">
                    <div className="px-8 f-details">
                        <h5>{data.footerAcf.city}</h5>
                        <p className="pt-2 pb-4">{data.footerInformationTemplate.address.addressLabel}</p>
                        <div className="w-full flex pl-0  pb-4">
                            <a href="https://www.facebook.com/NextYouNutrition/" target="_blank" rel="noopener"><img src={fbWhite} style={{ width: "45px" }} alt="facebook-icon" /></a>
                            <a href="https://www.instagram.com/nextyounutrition/" target="_blank" rel="noopener" className="pl-4"><img src={instagram} style={{ width: "45px" }} alt="instagram-icon" /></a>
                        </div>
                        <div className="f-seperator"></div>
                        <div className="flex items-center pt-8">
                            <div><img src={Telephone} style={{ width: "15px" }} alt={"telephone-icon"} /></div>
                            <div className="pl-4">
                                {data.footerInformationTemplate.telephoneNumber}
                            </div>
                        </div>
                        <div className="flex items-center pt-6">
                            <div><img src={Email} style={{ width: "15px" }} alt={"email-icon"} /></div>
                            <div className="pl-4">
                                {data.footerInformationTemplate.supportEmail}
                            </div>
                        </div>
                        <div className="flex items-center pt-6">
                            <div style={{ width: "12%" }}><img src={Clock} style={{ width: "15px" }} alt={"clock-icon"} /></div>
                            <div className="pl-4">
                                {data.footerInformationTemplate.hoursLabel}
                            </div>
                        </div>
                        <div className="pt-8">
                            <button className="h-appointment-btn flex f-app-bttn items-center" onClick={() => setAppointmentModal(true)}>
                                <span><img src={Calendar} style={{ width: "15px" }} alt={"calendar-icon"} /></span>
                                <span className="pl-2 f-btn-txt">{data.footerAcf.callToActionText}</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="py-8 text-center text-white">
                <Link to="/oroi-kai-proipotheseis/">Όροι και Προϋποθέσεις Χρήσης</Link>
                <span> | </span>
                <Link to="/gdpr/" className="inline-block mt-4 md:mt-0">Πολιτική Απορρήτου</Link>
                <p className="pt-2">©2020. Όλα τα δικαιώματα διατηρούνται.</p>
                <p className="relative text-center pt-2">Developed by <a href="https://w3vitals.com" rel="noopener" target="_blank" >W3Vitals</a></p>


            </div>

            <Modal
                isOpen={appointmentModal}
                onRequestClose={() => setAppointmentModal(false)}
                style={customStyles}
                contentLabel="Appointment Modal"
            >
                <AppointmentModal
                    cpt={props.appointment}
                    setAppointmentModal={setAppointmentModal}
                />
            </Modal>

        </footer>
    )
}

export default Footer;